import React, {FC} from 'react'
import classnames from 'classnames';
import "./DataRow.scss";

/*
 * A two column row meant for listing key/value data
 *
 * A list of these can be wrapped in the DataRows component,
 * allowing shared props and dividers
 *
 * fieldStyle boolean: displays in column format rather than inline (label above value)
 *
 * Example:
 * <DataRow label="Sale ID">{sale_id}</DataRow>
 */
type DataRowProps = {
  label:string|React.ReactNode;
  labelWidth?:number;
  className?:string;
  children:React.ReactNode;
  fieldStyle?: boolean;
}

export const DataRow:FC<DataRowProps> = ({
  label,
  labelWidth='150px',
  className="",
  children,
  fieldStyle
}) => {

  return (
    <div className={classnames('mst-data-row', className, {field:fieldStyle})}>
      <label style={{minWidth:labelWidth}}>{label}</label>
      <div className="value">
        {children}
      </div>
    </div>
  )
}
