import React, {FC} from 'react'
import {Stack, RowDivider} from "@thedmsgroup/mastodon-ui-components";

/*
 * A wrapper for a list of DataRow components.
 * Sets gap and divider between the rows
 * and a minimum label width that apply to all rows
 */
type DataRowsProps = {
  className?:string;
  children:React.ReactNode;
  labelWidth?:string;
  gap?:string;
  noDivider?:boolean;
  fieldStyle?: boolean;
}


export const DataRows:FC<DataRowsProps> = ({
           className="",
           children,
           labelWidth,
           gap="0.5em",
           noDivider,
           fieldStyle
         }) => {

  return (
    <div className={`mst-data-rows ${className}`}>
      <Stack gap={gap} divider={noDivider ? null : <RowDivider />} >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as any, { labelWidth, fieldStyle:child.props.fieldStyle ?? fieldStyle });
          }
          return child;
        })}
      </Stack>
    </div>
  )
}
