import React, {useState} from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { MoneyFormatter, PercentFormatter } from '../../../Components/Table/Table';
import {Stack} from "@thedmsgroup/mastodon-ui-components";
import Block from './Block';
import Bid from './Bid';
import AdDisplay from '../../AdManager/AdDisplay';
import EntityLinks from "./EntityLinks";
import EntityId from "../../../Components/Table/EntityId";
import {DataRows, DataRow} from "../../../Components";

/*
 * Auction match detail
 */
const Match = ({
  match_id,
  matchable_id,
  account,
  order,
  rule,
  channel,
  product,
  bid,
  account_bid_amount,
  account_bid_explanation,
  exclusive,
  blocks,
  tags,
  buyer_name,
  broker_name,
  internal_bid,
  internal_bid_modifiers,
  match_delivery_id,
  onSelectDelivery,
  onShowRouting,
  onShowMatchable,
}) => {

  return (
    <div className="match-container form-section">

      <div className="d-flex">
      <div className="match flex-grow-1">

        <div className="form-section-header">
          Match {match_id}
        </div>


        <DataRows labelWidth="120px">
          <DataRow label="Buyer">
            {buyer_name}
          </DataRow>

          <DataRow label="Broker">
          <>
            {match_delivery_id ? <Button
              className="inline"
              color="link"
              title={`Deliveries: ${broker_name}`}
              onClick={() => onSelectDelivery(match_delivery_id)}
            >
              {broker_name}
            </Button> : (broker_name || "-")}
          </>
          </DataRow>



          <DataRow label="Account">
            {account?.name}
          </DataRow>

          <DataRow label="Order">
            <>
              {account && order ? (
                  <EntityLinks
                    entityId={order.id}
                    accountId={account.id}
                    label={<span>{order.name} <EntityId id={order.id}/></span>}
                    linkPath={ `/accounts/${account.id}/order/${order.id}/rules`}
                    routingFilter="orderId"
                    openRoutingModal={onShowRouting}
                  />
              ) : (
                <i>unknown order</i>
              )}

            </>
          </DataRow>

          <DataRow label="Rule">
            <>
              {rule && account && (
                <>
                  <Link
                    target="_blank"
                    className="link"
                    to={{
                      pathname: `/accounts/${account.id}/order/${order.id}/rules`,
                      search: `rule=${rule.id}`,
                    }}
                    title="View rule in order rule tree"
                  >
                    {rule.label}
                  </Link> <EntityId id={rule.id} />
                </>

              )}
            </>
          </DataRow>

          <DataRow label="Channel">
            <>
              {channel ? (
                  <EntityLinks
                    entityId={channel.id}
                    label={<span>{channel.name} <EntityId id={channel.id} /></span>}
                    linkPath="/publishing/channels"
                    linkSearch={`page=1&search=${channel.name}`}
                    routingFilter="channelId"
                    openRoutingModal={onShowRouting}
                  />
              ) : '-'}
            </>
          </DataRow>

          <DataRow label="Exclusive">
            {exclusive ? 'yes' : 'no'}
          </DataRow>

          <DataRow label="Bid">
            <div>
              {account_bid_amount > 0 ? <MoneyFormatter value={account_bid_amount} /> : "-"}
              {account_bid_explanation?.length > 0 && <Explanation>
                <ul className={"list-unstyled"}>
                  {account_bid_explanation
                    .filter(reason => reason.indexOf("100%") === -1)
                    .map((reason, i) => <li key={i}>{reason}</li>)
                  }
                </ul>
              </Explanation>}
            </div>
          </DataRow>

          {internal_bid !== account_bid_amount &&<DataRow label="Internal Bid">
            <div>
              {internal_bid > 0 ? <MoneyFormatter value={internal_bid} /> : "-"}
              {internal_bid_modifiers?.length > 0 && <Explanation>
                <ul className={"list-unstyled"}>
                  {internal_bid_modifiers
                    .map((bm, i) => <li key={i}><InternalBidModifier {...bm} key={i} /></li>)
                  }
                </ul>
              </Explanation>}
            </div>
          </DataRow>}

          <DataRow label="Blocks">
            <>
              { blocks && blocks.length > 0 ? (
                <Stack gap="0.75em">
                  {blocks.map((b, i) => <Block {...b} key={i} />)}
                </Stack>
              ) : "-"
              }
            </>

          </DataRow>

          { tags && tags.length > 0 && (
            <DataRow label="Tags">
              {tags.join(', ')}
            </DataRow>
          )}



          <DataRow label="Matchable ID">
            <Button
              className="inline"
              color="link"
              title={`Matchable: ${matchable_id}`}
              onClick={() => onShowMatchable(matchable_id)}
            >
              {matchable_id}
            </Button>
          </DataRow>
        </DataRows>
      </div> {/* match */}


        <div className="match-bid flex-grow-1">
          {bid ? (
            <Bid {...bid} />
          ) : (
            <div className="bid no-bid">
              <div className="form-section-header mb-0 fs-5"><FaIcon icon="hand-holding-usd" /> No Bid Offered</div>
            </div>
          )}
        </div>

      </div>

      {/* Ads are in a separate full span row because of their size */}
      {product === 'clicks' && bid && (
        <>
          <DataRow label="Ad">

              <div className="ads-container">
                {bid.ad ? (
                  <div>
                    <AdDisplay {...bid.ad} />
                  </div>
                ) : "-"}
              </div>

          </DataRow>

          <DataRow label="Auto Redirect">
            <div>{bid.match.auto_redirect ? 'yes' : 'no'}</div>
          </DataRow>
        </>
      )}

    </div>
  );
};

const Explanation = ({children}) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <>
      <Button onClick={handleToggle} color="link" className="inline ms-2">why?</Button>
      {show && <div className="bid-explanation">
        {children}
      </div>}
    </>
  );
};

const InternalBidModifier = ({ amount, source }) => (
  <span>
    <PercentFormatter value={amount} />
    {' '}
    {source}
  </span>
);

export default Match;
