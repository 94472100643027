import React, { useState } from 'react';
import {RelativeTime} from "@thedmsgroup/mastodon-ui-components";
import {RequestLog, ResponseLog} from "@thedmsgroup/mastodon-ui-components/lib/components/HttpLog/HttpLog";
import {Alert, Button} from "reactstrap";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import {DataRow, DataRows} from "../../../Components";
import classnames from "classnames";

const HttpLog = ({log}) => {
  return <div className="delivery flex-grow-1" key={log.request_id}>
    <DataRows>
      <DataRow label="Request ID">
        {log.request_id}
      </DataRow>

      <DataRow label="Send At">
       <RelativeTime date={log.sent_at} />
      </DataRow>

      <DataRow label="Duration">
        {log.response_time} ms
      </DataRow>

      <DataRow label="Status Code">
        {log.status_code}
      </DataRow>

      <DataRow label="Request">
          <ToggleMe>
            <><HelpText>This is the actual request sent to the client. It is safe to share with the client.</HelpText>
              {log.request && <RequestLog requestLog={log.request} />}</>
          </ToggleMe>
      </DataRow>

      <DataRow label="Response">
          <ToggleMe>
            <><HelpText>This is the actual response received from the client. It is safe to share with the client.</HelpText>
              {log.response && <ResponseLog responseLog={log.response} bodyHeight={300} />}</>
          </ToggleMe>
      </DataRow>
    </DataRows>

  </div>
}

// todo extract to utilities
const HelpText = ({children}) => <Alert color="secondary">{children}</Alert>

// todo extract to utilities
const ToggleMe = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <div className="toggle-me">
      <Button color="link" size="sm" className="inline d-flex align-items-center" onClick={handleToggle}>
        <FaIcon icon="eye" className="me-1" />
        <div>{show ? 'hide' : 'show'}</div>
      </Button>
      <div className={classnames(['data', { show }])}>
        {children}
      </div>
    </div>
  );
};

export default HttpLog
