import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, FormGroup, FormText, FormFeedback, Label, Input,
} from 'reactstrap';
import {MaskedInput, MaskedInputGroup} from "@thedmsgroup/mastodon-ui-components/lib/components/Form/MaskedInput";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import useImaskNiceAccept from "../../../Hooks/useImaskNiceAccept";
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import BidWidget from '../../../Components/Rules/BidWidget';
import classnames from 'classnames';

// Mask ddd,ddd
const numberMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  integerLimit: 4,
});


const DurationMaskOptions = {
  mask: Number,
  unmask: true,
  scale:0,
  min:0,
  max:300,
  signed: false,
}
const PercentMaskOptions = {
  mask: Number,
  unmask: true,
  scale:0,
  min:0,
  max:999,
  signed: false,
}

const CurrencyMaskOptions = {
  mask: Number,
  unmask: true,
  padFractionalZeros: true,
  normalizeZeros: true,
  scale:2,
  min:0,
  max:9999,
  radix:".",
  thousandsSeparator:",",
  signed: false,
}

const Settings = ({
  product,
  bidding,
  bidFloor,
  bidFalloff,
  flowControl,
  sellStrategy,
  billableCallDuration,
  onChange,
  isActiveTab,
  errors,
}) => {

  const {onAccept:onAcceptBillableDuration} = useImaskNiceAccept((val, mask)=>onChange('billable_call_duration', mask.masked.unmaskedValue));
  const {onAccept:onAcceptBidFalloff} = useImaskNiceAccept((val, mask)=>onChange('bid_falloff', mask.masked.unmaskedValue));
  const {onAccept:onAcceptBidFloor} = useImaskNiceAccept((val, mask)=>onChange('account_bid_floor', mask.masked.unmaskedValue));


  return (
    <div>
      {Object.keys(errors).length > 0
      && (
      <StandardAlert color="info" className="validation">
        Please correct the errors below before continuing
      </StandardAlert>
      )}

      <div className="form-section">
        <div className="form-section-header">Payout/Margin</div>

        <BidWidget
          {...bidding}
          onChange={onChange}
          isActiveTab={isActiveTab}
          errors={errors}
          ruleType="routing"
        />

          <FormGroup className="d-inline-block me-3">
            <Label>Bid Floor</Label>

            <MaskedInputGroup
              value={bidFloor || bidFloor === 0 ? bidFloor.toString() : '' }
              onAccept={onAcceptBidFloor}
              bsSize="sm"
              invalid={!!errors.bid_floor}
              className="teeny"
              prefix="$"
              {...CurrencyMaskOptions}
              />
        </FormGroup>

      </div>

      <div className="form-section">
        <div className="form-section-header">Selling Strategy</div>
        <Row>
          <Col sm={12} md={8}>
            <FormGroup tag="fieldset">

              <FormGroup check  className={classnames('detailed', {selected:!sellStrategy})}>
                <div>
                  <Input
                    name="sell_strategy"
                    type="radio"
                    value=""
                    defaultChecked={!sellStrategy}
                    onChange={onChange}
                  />
                  {' '}
                  <Label check>
                    Default / Inherit
                  </Label>
                </div>
                <FormText>Use the strategy of the parent rule, or default to Accept Once.</FormText>
              </FormGroup>
              <FormGroup check className={classnames('detailed', {selected:sellStrategy === 'accept_once'})}>
                <div>
                  <Input
                    name="sell_strategy"
                    type="radio"
                    value="accept_once"
                    defaultChecked={sellStrategy === 'accept_once'}
                    onChange={onChange}
                  />
                  {' '}
                  <Label check>
                    Accept Once
                  </Label>
                  <FormText>Sell to the highest bid. Do not attempt to sell to other bids.</FormText>
                </div>

              </FormGroup>
              <FormGroup  check  className={classnames('detailed', {selected:sellStrategy === 'waterfall'})}>
                <div>
                  <Input
                    name="sell_strategy"
                    type="radio"
                    value="waterfall"
                    defaultChecked={sellStrategy === 'waterfall'}
                    onChange={onChange}
                  />
                  {' '}
                  <Label check>
                    Waterfall
                  </Label>
                </div>
                <FormText>In order from highest to lowest bid, sell to the first bidder that accepts.</FormText>

              </FormGroup>
            </FormGroup>
          </Col>
        </Row>

      </div>

      <div className="form-section">
          <div className="form-section-header">Bid Falloff</div>

          <FormGroup >
            <MaskedInputGroup
              value={bidFalloff || bidFalloff === 0 ? bidFalloff.toString() : ''}
              onAccept={onAcceptBidFalloff}
              bsSize="sm"
              invalid={!!errors.bid_falloff}
              className="teeny text-end"
              suffix="%"
              {...PercentMaskOptions}
            />
          </FormGroup>

          <p>The percentage difference to top bid at which lesser bids drop off (i.e. don&apos;t show bids lower than 80% of top bid)</p>
          <p>This &quot;compresses&quot; bids towards the top and prevents a low bid from distracting from a high bid.</p>
          <p>Example: <ul><li>original bids: $10, $8, $7</li><li>bid falloff = 80%</li><li>= $8 threshold (80% of $10)</li><li>= new bids: $10, $8</li></ul></p>

      </div>

      <div className="form-section">
        <div className="form-section-header">Flow Control</div>

        <p>Restrictively include (whitelist) or exclude (blacklist) matching leads. This setting will apply to all descendant rules unless overidden.</p>
        <p>
          If not set, targeting will not by itself limit leads. This is preferred if you use targeting to apply other settings, such as
          payout, caps, or schedule.
        </p>

        <FormGroup className="mt-2 d-flex">
          <div style={{ flexBasis: '400px' }}>
            <Input
              type="select"
              name="flow_control"
              value={flowControl || ''}
              onChange={onChange}
            >
              <option value="">None</option>
              <option value="include">Include only leads that match target conditions</option>
              <option value="exclude">Exclude all leads that match target conditions</option>
            </Input>
          </div>

        </FormGroup>
      </div>

      {product === 'calls' && (
        <div className="form-section">
          <div className="form-section-header">Call Settings</div>
          <FormGroup>
            <Label>Billable Call Duration (seconds)</Label>

            <MaskedInput
              value={billableCallDuration || billableCallDuration === 0 ? billableCallDuration.toString() : ''}
              onAccept={onAcceptBillableDuration}
              bsSize="sm"
              placeholder="30"
              invalid={!!errors.billable_call_duration}
              className="teeny"
              {...DurationMaskOptions}
            />
            <FormText>Number of seconds before the call becomes billable. Defaults to 30 seconds. Maximum: 300 (5 minutes).</FormText>
            <FormFeedback>{errors.billable_call_duration}</FormFeedback>

          </FormGroup>
        </div>
      )}

    </div>
  );
};

Settings.propTypes = {
  bidding: PropTypes.object,
  bidFloor: PropTypes.number,
  bidFalloff: PropTypes.number,
  billableCallDuration: PropTypes.number,
  isActiveTab: PropTypes.bool,
  flowControl: PropTypes.string,
  sellStrategy: PropTypes.string,
  product: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default Settings;
