import React from 'react';
import PropTypes from 'prop-types';
import {Badge} from "reactstrap";
import { tokenToWords } from '../../../utils/string';
import {JsonDisplay} from '@thedmsgroup/mastodon-ui-components';
import {DataRows, DataRow} from "../../../Components";

/* Block reason and details */
const Block = React.memo(({ reason='', details={}, indentClass="", compact }) =>
  // Reason (str) Details(object with various keys)
  (
    <div className="block d-flex flex-column">
      {/*<div className={`reason ${indentClass} mb-2`}><span>{tokenToWords(reason)}</span></div>*/}
      <Badge className="mb-2" size="sm" color="danger">{tokenToWords(reason)}</Badge>

        {details && Object.keys(details).length > 0 ? (
          <DataRows
            className={indentClass}
            labelWidth={compact ? '100px' : '125px'}
            fieldStyle={compact}
            noDivider
          >
            {
              Object.entries(details).map(([dtKey, val], i) => (
                <DataRow label={tokenToWords(dtKey)} key={i}>
                  <div>{typeof val === 'object' ? <JsonDisplay data={val} scrollable={false} /> : val}</div>
                </DataRow>
              ))
            }
          </DataRows>
        ) : (
          <i>No details</i>
        ) }

    </div>

  ));


Block.propTypes = {
  reason: PropTypes.string,
  details: PropTypes.object,
  compact: PropTypes.bool
};

export default Block;

/*
Example
"Blocks":[
            {
               "Reason":"proxy_rejected",
               "Details":{
                  "delivery_id":154624270,
                  "disposition":"",
                  "integration_name":"alm_pingpost_auto_calls",
                  "reject_category":"duplicate",
                  "reject_details":"Duplicate lead found.",
                  "suppression":{
                     "Name":"alm_pingpost_auto_calls",
                     "Post":false,
                     "Settings":{
                        "api_key":"96087e6d2f3638a0e6e9292d14d376d37bfde9a1",
                        "passthrough_external_id":"vendor_external"
                     }
                  }
               }
            }
         ],
 */
