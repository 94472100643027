import React from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import Block from './Block';
import {DataRows, DataRow} from "../../../Components";
import { MoneyFormatter } from '../../../Components/Table/Table';
import {
  HelpPopper,
  RelativeTime,
  UrlDisplayWidget,
  ExpandableSection,
  Stack
} from '@thedmsgroup/mastodon-ui-components';

/*
 * Auction bid detail
 */
const Bid = (bid) => {
  const {
    position,
    transaction_id,
    account_bid,
    vendor_bid,
    internal_bid_modifiers,
    adjusted_account_bid,
    blocks,
    sales = [],
    sale,
    sale_attempt,
  } = bid

  return <div className="bid">
    <div className="form-section-header mb-2 fs-5">
      {sale ? (
        <span>
          <FaIcon icon="star" color="gold"/>
          {' '}
          Bid Sold {sale.reverted_at && '(Reverted)'}
        </span>
      ) : <span><FaIcon icon="hand-holding-usd"/> Bid Offered</span>}
    </div>


  <DataRows labelWidth="100px" gap="0.25em" >
    <DataRow label="Position" >
      {position}
    </DataRow>
    <DataRow label="Sale Attempt" >
      {sale_attempt}
    </DataRow>
    <DataRow label="Account Bid">
      <div>
        {account_bid ? <MoneyFormatter value={account_bid}/> : <i>none</i>}
        {adjusted_account_bid > 0 && adjusted_account_bid !== account_bid && <>
          {' '}<FaIcon icon="arrow-right"/> <MoneyFormatter value={adjusted_account_bid}/>
          <HelpPopper name="what-is-this" iconClass="ms-2">The buyer has adjusted their bid on the sale</HelpPopper>
        </>}
      </div>
    </DataRow>
    <DataRow label="Vendor Bid">
      {vendor_bid ? <MoneyFormatter value={vendor_bid}/> : <i>none</i>}
    </DataRow>

    {transaction_id && <DataRow label="Transaction ID">
      <span>{transaction_id}</span>
    </DataRow>}

    <DataRow label="Blocks" fieldStyle>

        {blocks && blocks.length > 0 ? (
          <Stack gap="0.5em" className="ms-3">
            {blocks.map((b, i) => <Block {...b} compact key={i}/>)}
          </Stack>
        ) : (
          "-"
        )}


    </DataRow>

    {sale && (
      <DataRow label="Sale" fieldStyle>
        <DataRows className="sale ms-3" fieldStyle>

            <DataRow label="Sale ID">
              {sale.sale_id}
            </DataRow>
            {sale.redirect_url && <DataRow label="Redirect URL">
              <div>
                <ExpandableSection>
                  <UrlDisplayWidget title="Redirect URL" url={sale.redirect_url}/>
                </ExpandableSection>
              </div>
            </DataRow>}

            <DataRow label="When">
              <RelativeTime date={sale.sold_at}/>
            </DataRow>

            {sale.reverted_at && <DataRow label="Reverted">
              <div><RelativeTime date={sale.reverted_at}/> by user: {sale.reverted_by} because: {sale.revert_reason}</div>
            </DataRow>}

        </DataRows>
      </DataRow>
    )}

    <DataRow label="Public">
      <div>{bid.public ? 'yes' : 'no'}</div>
    </DataRow>

  </DataRows>


  </div>
}

export default Bid;
