import React from 'react';
import PropTypes from 'prop-types';
import {MoneyFormatter} from "../../../Components/Table/Table";
import {StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import {DataRows, DataRow} from "../../../Components";

/* Display data from received_call   */
const BiddingLog = ({ auction }) => (
  <>
      <div className="form-section">
        <div className="form-section-header">Exclusive Bidding Decisions</div>

        {auction.logs?.exclusive_bidding && (
          <DataRows noDivider>
            <DataRow label="Shared Estimate">
                {<MoneyFormatter value={auction.logs.exclusive_bidding.shared_estimate} />}
            </DataRow>

            <DataRow label="Shared Explanation">
              <div>
                <pre>{auction.logs.exclusive_bidding.shared_explanation}</pre>
              </div>
            </DataRow>
          </DataRows>
        )}

        {!auction.logs?.exclusive_bidding && <StandardAlert color="light">
          No exclusive bidding decisions were made.
        </StandardAlert>}

        {auction.logs?.selling && <div className="mt-2">
          <div className="form-section-header">Selling Decisions</div>
          <pre>{auction.logs.selling}</pre>
        </div>}

      </div>
  </>
)

BiddingLog.propTypes = {
  auction: PropTypes.object,
}

export default BiddingLog;
