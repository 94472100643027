import React, { useState, useEffect } from 'react';
import useApi from "../../../Hooks/useApi";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import HttpLog from "./HttpLog";
import LoaderDots from "../../../Layout/LoaderDots";
const HttpLogs = ({ auctionId }) => {
  const { api } = useApi();
  const [logs, setLogs] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadLogs = async (auctionId) => {
    setLoading(true)
    const result = await api.endpoints.debug.auctionHttpLogs(auctionId);

    if (result?.http_logs) {
      setLogs(result.http_logs.sort((a, b) => {
        return a.sent_at > b.sent_at ? 1 : -1
      }))
    } else {
      setLogs(false)
      notify(`Unable to load http logs: ${api.error.name}`, 'error')
    }

    setLoading(false)
  }

  useEffect(() => {
    loadLogs(auctionId)
  }, [auctionId])

  return <div className="form-section delivery flex-grow-1">
    <LoaderDots active={loading} width={160} />
    {logs && logs.map(log => {
      return <div className={"mb-4"}><HttpLog log={log} /></div>
    })}
    {logs === false && <div>Http logs are only retained for 7 days...</div>}
  </div>
}

export default HttpLogs
